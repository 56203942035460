import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import { LocalizeProvider } from "react-localize-redux";
import { Route } from 'react-router-dom';
import store, { history } from './store';

import App from './containers/App';
import withTracker from './components/analytics/withTracker';

import './index.css';
import 'antd/lib/alert/style/css';
import 'antd/lib/button/style/css';
import 'antd/lib/breadcrumb/style/css';
import 'antd/lib/card/style/css';
import 'antd/lib/carousel/style/css';
import 'antd/lib/checkbox/style/css';
import 'antd/lib/date-picker/style/css';
import 'antd/lib/divider/style/css';
import 'antd/lib/drawer/style/css';
import 'antd/lib/dropdown/style/css';
import 'antd/lib/form/style/css';
import 'antd/lib/input/style/css';
import 'antd/lib/menu/style/css';
import 'antd/lib/message/style/css';
import 'antd/lib/modal/style/css';
import 'antd/lib/notification/style/css';
import 'antd/lib/pagination/style/css';
import 'antd/lib/popconfirm/style/css';
import 'antd/lib/radio/style/css';
import 'antd/lib/rate/style/css';
import 'antd/lib/select/style/css';
import 'antd/lib/steps/style/css';
import 'antd/lib/spin/style/css';
import 'antd/lib/switch/style/css';
import 'antd/lib/table/style/index.css';
import 'antd/lib/upload/style/css';
// import './video-js.css';
import './css/bc-style-vjs.css';
import './css/bc-style-default.css';

import registerServiceWorker from './registerServiceWorker';
// import { unregister } from './registerServiceWorker';

const target = document.querySelector('#root');

render(
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<LocalizeProvider>
				<Route
					component={withTracker(App, {
						/* additional attributes */
					})}
				/>
			</LocalizeProvider>
		</ConnectedRouter>
	</Provider>,
  target
);

registerServiceWorker();

// unregister();
