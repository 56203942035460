import React, { Component } from 'react';
import GoogleAnalytics from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module';
import config from '../../config';
import gtmModule from './gtmModule';

// const oneTrustGAType = 'ad';
// const gaEnable = (window.OnetrustActiveGroups)?(window.OnetrustActiveGroups.indexOf(oneTrustGAType) > -1)?true:false:false;
// const oneTrustGTMType = 'ad';
// const gtmEnable = (window.OnetrustActiveGroups)?(window.OnetrustActiveGroups.indexOf(oneTrustGTMType) > -1)?true:false:false;
// const oneTrustPixelType = 'ad';
// const pixelEnable = (window.OnetrustActiveGroups)?(window.OnetrustActiveGroups.indexOf(oneTrustPixelType) > -1)?true:false:false;

const gaEnable = true;
const gtmEnable = true;
const pixelEnable = true;

let gaURL = config.ga;
let pixelID = '';
let gtmConfig = {};

if (window.location.origin.indexOf('www.hbogoasia.') > -1 || window.location.origin.indexOf('www.hbogo.') > -1 ) {
	pixelID = config.pixel.prod;
	gtmConfig = {
	    gtmId: config.gtm.prod
	};
} else if (window.location.origin.indexOf('web-hbouat') > -1) {
	pixelID = config.pixel.uat;
	gtmConfig = {
	    gtmId: config.gtm.uat
	};
} else if (window.location.origin.indexOf('web-hbostag') > -1) {
	pixelID = config.pixel.uat;
	gtmConfig = {
	    gtmId: config.gtm.uat
	};
} else {
	pixelID = config.pixel.dev;
	gtmConfig = {
	    gtmId: config.gtm.dev
	};
}

const options = {
	autoConfig: true, 	// set pixel's autoConfig
	debug: false, 		// enable logs
};

// const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
// ReactPixel.track( event, data ) 		// For tracking default events, more info about events and data https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.9
// ReactPixel.trackCustom( event, data ) 	// For tracking custom events
if(gaEnable){
	GoogleAnalytics.initialize(gaURL);
}
if(pixelEnable){
	ReactPixel.init(pixelID, /*advancedMatching,*/ {}, options);
}


if(gtmEnable){
	TagManager.initialize(gtmConfig);
	window.addEventListener('message', function(evt){
		if(config.gtmFlag && evt.data.gtmEvent){
			let gtm_ev = JSON.parse(JSON.parse(evt.data.gtmEvent));
			if(window.gtm_debug){
				console.log('EV',	gtm_ev);
	    }
			gtmModule(gtm_ev.event_category, gtm_ev.event_action, {label:gtm_ev.event_label}, {screen_name:gtm_ev.screen_name});
	  }
	});
}

const withTracker = (WrappedComponent, options = {}) => {
	const trackPage = page => {
		if(gaEnable){
			GoogleAnalytics.set({
				page,
				...options
			});
		}
		if(pixelEnable){
			ReactPixel.pageView();
		}
	};

	const HOC = class extends Component {
		componentDidMount() {
			const page = this.props.location.pathname;
			const actualPage = page.split('/')[1];
			trackPage(`/#${actualPage}`);
		}

		UNSAFE_componentWillReceiveProps(nextProps) {
			const currentPage = this.props.location.pathname;
			const nextPage = nextProps.location.pathname;

			if (currentPage !== nextPage) {
				const actualPage = nextPage.split('/')[1];

				trackPage(`/#${actualPage}`);
			}
		}

		render() {
			return <WrappedComponent {...this.props} />;
		}
	};

	return HOC;
};

export default withTracker;
