import TagManager from 'react-gtm-module';
import config from "../../config";

const gtmModule = (event_category, event_action, event_label, extra_data = false) => {
  let userData = localStorage.u;
  let userInfo = null;
  let user_id = '';
  let subscription_end_date = '';
  let signin_type = '';
  let country_code = localStorage.real_territory;
  let videoInfo = {};
  let otherInfo = {};
  let user_birthdate = '';
  let subscription_start_date = '';
  let login_status = localStorage.u ? 'Logged in' : 'Guest';
  let payment_mode = '';
  let last_login_date = '';
  let platform = 'Desktop';
  let last_video_watched_date = localStorage.last_video_watched_date;


  if (userData) {
    userData = JSON.parse(localStorage.u);
    user_id = userData.spAccountID;
    let unix_timestamp = parseInt(userData.accountData.subscriptionExpDateTime);
    var date = new Date(unix_timestamp);
    subscription_end_date = date.getFullYear()+
                            (date.getMonth()+1).toString().padStart(2,'0')+
                            date.getDate().toString().padStart(2,'0');

    if(userData.subscriptionHistory && userData.subscriptionHistory.AccountServiceMessage){
      var subscriptionStartDateTime = userData.subscriptionHistory.AccountServiceMessage.filter(e=>{return e.validityTill === parseInt(userData.accountData.subscriptionExpDateTime)})[0];
      if(subscriptionStartDateTime && subscriptionStartDateTime.startDateInMillis){
        unix_timestamp = parseInt(userData.accountData.subscriptionExpDateTime);
        date = new Date(unix_timestamp);
        subscription_start_date = date.getFullYear()+
                                (date.getMonth()+1).toString().padStart(2,'0')+
                                date.getDate().toString().padStart(2,'0');
      }
    }




    signin_type = userData.operatorName;
    if (userData.contactMessage && userData.contactMessage.dateOfBirth) {
      let birth_unix_timestamp = parseInt(userData.contactMessage.dateOfBirth);
      var birth_date = new Date(birth_unix_timestamp);
      user_birthdate = birth_date.getFullYear()+
                        (birth_date.getMonth()+1).toString().padStart(2,'0')+
                        birth_date.getDate().toString().padStart(2,'0');
    }
    payment_mode = userData.accountData && userData.accountData.paymentMethod ? userData.accountData.paymentMethod : '';
    var ll_date = new Date(unix_timestamp);
    last_login_date = ll_date.getFullYear()+
                        (ll_date.getMonth()+1).toString().padStart(2,'0')+
                        ll_date.getDate().toString().padStart(2,'0');





    userInfo = {
      user_id,
      subscription_end_date,
      subscription_start_date,
      signin_type,
      user_birthdate,
      payment_mode,
      last_login_date
    };

    if (signin_type) {
      userInfo = {
        ...userInfo,
        operator: userData.channelPartnerID,
      }
    }
  }

  let event = 'hm_push_event';
  const getLocation = (x) => {
    if (x === '/') {
      return 'Home';
    } else if (x === '/movies') {
      return 'Movies';
    } else if (x === '/series') {
      return 'Series';
    } else if (x === '/asian') {
      return 'Asian';
    } else if (x === '/documentaries') {
      return 'Documentaries';
    } else if (x === '/kids_family') {
      return 'Kids_And_Family';
    } else if (x === '/specials') {
      return 'Comedy_Specials';
    } else {
      return x;
    }
  }

  let label = '';
  if(event_label.step){
    label = 'Step='+event_label.step;
  }else if(event_category === 'HBOGO-Engagement' && event_label.title && event_label.loc) {
    label = 'Title='+event_label.title+',Loc='+getLocation(event_label.loc);
  }else if(event_label.title){
    label = 'Title='+event_label.title;
  }else if(event_label.SearchId){
    label = 'Title=SearchId:'+event_label.SearchId+',Search Keyword:'+event_label.Keyword+',ResultCount:'+event_label.ResultCount;
  }else if(event_label.depth){
    label = 'Depth='+event_label.depth;
  }else if(event_label.label){
    label = event_label.label;
  }
  if(extra_data && ('contentType' in extra_data)){
    let video = extra_data;
    // console.log('video', video);
    videoInfo = {
      video_category: (video.metadata.categories)?video.metadata.categories[0]:video.contentType,
      video_sub_category: video.metadata.genre ||'',
      video_details: (video.contentType !== 'promo' && video.metadata.productionDate)?(`${video.metadata.productionDate.substr(0,4)} | ${video.metadata.rating} | `+(video.subType | "")+` | `+(video.lang | "")+` | `+(video.starRating | 0)).toUpperCase():'',
      video_name: video.metadata.titleInformations[0].name,
      video_id: video.contentId,
      video_duration: video.duration || video.metadata.duration,
      video_auto_played: false,
      is_trailer: (video.contentType === 'promo'),
      series_name_season:(video.seasonTitleInformations)?`${video.seasonTitleInformations[0].name}`:'',
      livetv_channel_name: (event_label.loc && event_label.loc === 'livetv')?event_label.title:"",
      player_session_id: localStorage['Conviva.sdkConfig'] ? JSON.parse(localStorage['Conviva.sdkConfig']).clId : '',
      series_season: video.seasonNumber ||'',
      series_episode: video.episodeNumber ||'',
      play_duration: video.play_duration || '',
    }
    if(video.video_90_completed){
      videoInfo.video_90_completed = video.video_90_completed;
    }
    if(video.player_subtitle){
      videoInfo.player_subtitle = video.player_subtitle;
    }
    if(video.player_audiotrack){
      videoInfo.player_audiotrack = video.player_audiotrack;
    }
    if(video.player_videoquality){
      videoInfo.player_videoquality = video.player_videoquality;
    }


  }else if(extra_data && ('screen_name' in extra_data)){
    otherInfo =  extra_data;
  } else {
    videoInfo = {
      video_category: '',
      video_sub_category: '',
      video_details: '',
      video_name: '',
      video_id: '',
      video_duration: '',
      video_auto_played: '',
      is_trailer: '',
      series_name_season: '',
      livetv_channel_name: '',
      player_session_id: '',
      series_season: '',
      series_episode: '',
      play_duration: '',
      video_90_completed: '',
      player_subtitle: '',
      player_audiotrack: '',
      player_videoquality: '',
    };
  }
  
  // set default value, prevent braze cache the value from last event
  otherInfo.loc = '';
  otherInfo.title_index = '';
  otherInfo.collection_name = '';
  otherInfo.loading_time = '';
  otherInfo.ab_test_type = '';

  if (event_label.loc) {
    otherInfo.loc = localStorage.getItem('previousPage') === '/' && event_label.loc !== '/' && event_category !== 'HBOGO-Engagement' 
      ? `Home_${getLocation(event_label.loc)}` 
      : getLocation(event_label.loc);
  }
  if (event_label.title_index) {
    otherInfo.title_index = event_label.title_index;
  }
  if (event_label.collection_name) {
    otherInfo.collection_name = event_label.collection_name === 'recommendation' ? 'Recommendation For You' : event_label.collection_name;
  }
  if (event_label.loading_time) {
    otherInfo.loading_time = event_label.loading_time;
  }
  if (event_label.ab_test_type) {
    otherInfo.ab_test_type = event_label.ab_test_type;
  }

  event_label = label;

  let dataLayerArgs = {
      event,
      event_category,
      event_action,
      event_label,
      ...userInfo,
      country_code,
      login_status,
      platform,
      last_video_watched_date,
      ...videoInfo,
      ...otherInfo
    };
  if(config.gtmFlag && country_code){
    if(window.gtm_debug){
      console.log('GTM', config.gtmFlag, dataLayerArgs, extra_data);
      console.log('GTM detail', {
        'Event Category': dataLayerArgs.event_category,
        'Event Action': dataLayerArgs.event_action,
        'Event Label': dataLayerArgs.event_label,
        'collection_name': dataLayerArgs.collection_name,
        'loc': dataLayerArgs.loc,
        'title_index': dataLayerArgs.title_index,
      });
    }
    TagManager.dataLayer({dataLayer: dataLayerArgs});
  }
};


export default gtmModule;
