import React, { Component, Suspense, lazy } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';

import { withLocalize } from "react-localize-redux";
import { renderToStaticMarkup } from "react-dom/server";
import { isMobile, isTablet } from "react-device-detect";
import {Helmet} from "react-helmet";
import $ from 'jquery';

import config from "../config";
import translation from "../config/translate";
import translation_helmet from "../config/translate/helmet.json";
import translation_helmet_hk from "../config/translate/helmet_HKG.json";
import Translate from '../components/translate/Translate';
import gaModule from '../components/analytics/gaModule';
import gtmModule from '../components/analytics/gtmModule';
import Routing from '../components/routing/Routing';

import './app.css';

import {
	appSetLanding,
	appSetFirstLogin,
  appSetLanguage,
  appSetHoldAccCheck,
	appSetEmbedResume,
	appEslint,
	appQuery,
  appSetEmbedMode,
	appSetPrompt
} from '../modules/app';
import {
	userSetDeviceInfo,
	userGetCountry,
	userSetAuthentication,
	userGetProfile,
	userAuthenticateCustomerForOperator,
  userLogout,
  userValidateOperatorJWT,
  userSetPreviousURL,
  userInitialBraze,
  userSetPreviousPage,
  userSetBeforePlay,
} from '../modules/user';

import {
	translationGetList,
} from '../modules/translation';

import {
	liveGetChannel,
} from '../modules/live';

import {
	seriesGetSpecific,
} from '../modules/series';

import {
	searchGetContent,
} from '../modules/search';

import {
  micrositeGetSpecific,
} from '../modules/microsite';

import {
  promotionCancelPromo,
} from '../modules/promotion';

import {
	overlayGetContent,
} from '../modules/overlay';

import { 
  landingGetContent
} from '../modules/landing';

import { 
  sysInfoGetImpression,
  sysInfoGetRecommendation,
  sysInfoGetDeleteAcc,
} from '../modules/sysinfo';

/* Page Containers */
const Home = lazy(() => import('./home/Home'));
const VOD = lazy(() => import('./vod/VOD'));
const LiveTV = lazy(() => import('./livetv/all/LiveTV'));
const LiveChannel = lazy(() => import('./livetv/channel/LiveChannel'));
const Movie = lazy(() => import('./movie/Movie'));
const Series = lazy(() => import('./series/list/Series'));
const IndividualSeries = lazy(() => import('./series/individual/IndividualSeries'));
const Collection = lazy(() => import('./collection/list/Collection'));
const IndividualCollection = lazy(() => import('./collection/individual/IndividualCollection'));
const GenericSearchGenre = lazy(() => import('./genericsearchgenre/GenericSearchGenre'));
const FreeContent = lazy(() => import('./freecontent/FreeContent'));
const HKSignIn = lazy(() => import('./signin/SignIn'));
const HKSignUp = lazy(() => import('./signup/SignUp'));
const HKParentalControl = lazy(() => import('./parentalcontrol/ParentalControl'));
const HKBilling = lazy(() => import('./billing/Billing'));
const Search = lazy(() => import('./search/Search'));
const Setting = lazy(() => import('./setting/Setting'));
const Watchlist = lazy(() => import('./watchlist/Watchlist'));
const SignUp = lazy(() => import('./customer/SignUp'));
const SignIn = lazy(() => import('./customer/SignIn'));
const Billing = lazy(() => import('./customer/Billing'));
const ResetPassword = lazy(() => import('./customer/ResetPassword'));
const CreditCard = lazy(() => import('./customer/CreditCard'));
const Profile = lazy(() => import('./profile/Profile'));
const SystemPage = lazy(() => import('./systempage/SystemPage'));
const Device = lazy(() => import('./device/Device'));
const OutOfService = lazy(() => import('./outofservice/OutOfService'));
const Landing = lazy(() => import('./landing/Landing'));
const OrderHBOGO = lazy(() => import('./orderhbogo/OrderHBOGO'));
const Iframe = lazy(() => import('./iframe/Iframe'));
const Activate = lazy(() => import('./activate/Activate'));
const ParentalControl = lazy(() => import('./customer/ParentalControl'));
const Microsite = lazy(() => import('./microsite/Microsite'));
const Partner = lazy(() => import('./partner/Partner'));
const Unsubscribe = lazy(() => import('./unsubscribe/Unsubscribe'));
const MyAccount = lazy(() => import('./myaccount/MyAccount'));
const Invoice = lazy(() => import('./invoice/Invoice'));
const Promotion = lazy(() => import('./promotion/Promotion'));
// const TVSchedule = lazy(() => import('./tvschedule/TVSchedule'));
const LandingVer2 = lazy(() => import('./landingver2/LandingVer2'));
const NotFound = lazy(() => import('./404/404'));
const KidsFamily = lazy(() => import('./kidsfamily/KidsFamily'));
const EnhancedCollection = lazy(() => import('./enhancedcollection/EnhancedCollection'));
const Operator = lazy(() => import('./customer/Operator'));

/* Components */
const Toppanel = lazy(() => import('../components/toppanel/Toppanel'));
const Footer = lazy(() => import('../components/footer/Footer'));
const Prompt = lazy(() => import('../components/prompt/Prompt'));
const Message = lazy(() => import('../components/message/Message'));

/* Routes */
const HOMEROUTE = '/';

const SIGNINROUTE = '/signin';
const SIGNUPROUTE = '/signup';
const PROFILEROUTE = '/profile';
const RESETPINROUTE = '/resetparentalcontrolpin';
const RESETPASSWORDROUTE = '/resetpassword';
const UPDATEPARENTALROUTE = '/updateparentalcontrols';
const DEVICEROUTE = '/login-error';
const ACTIVATEROUTE = '/activate';
const BILLINGROUTE = '/billing';
const BILLINGCCROUTE = '/billing/creditcard';
const BILLINGPROMOROUTE = '/billing/promotion';
const BILLINGOPERATORROUTE = '/billing/operator';
const PARENTALCONTROLROUTE = '/parentalcontrol';

const ORDERROUTE = '/order_hbo';

const PRIVACYROUTE = '/privacy';
const TERMSROUTE = '/terms_of_service';
const LEGALROUTE = '/licenses';
const CONTACTROUTE = '/help/contact_us';
const FAQROUTE = '/help/faq';
const TVSCHEDULEROUTE = '/tvschedule';

const MOVIELISTROUTE = '/movies';
const MOVIEVODROUTE = '/movies/:id?';

const ASIANROUTE = '/asian';
const ASIANSERIESROUTE = '/asian/sr:id?';
const ASIANVODROUTE = '/asian/:id?';

const LIVETVALLROUTE = '/livetv';
const LIVECHANNEL = '/livetv/:id?';

const SERIESLISTROUTE = '/series';
const SERIESROUTE = '/series/sr:id?';
const EPISODEVODROUTE = '/series/:id?';

const PROMOROUTE = '/extras/:id?';

const COLLECTIONLISTROUTE = '/collections';
const COLLECTIONROUTE = '/collections/:id?';

const DOCUMENTARYROUTE = '/documentaries';
const DOCUMENETARYSERIESROUTE = '/documentaries/sr:id?';
const DOCUMENETARYVODROUTE = '/documentaries/:id?';

const FAMILYROUTE = '/kids_family';
const FAMILYSERIESROUTE = '/kids_family/sr:id?';
const FAMILYVODROUTE = '/kids_family/:id?';

const SPECIALSROUTE = '/specials';
const SPECIALSSERIESROUTE = '/specials/sr:id?';
const SPECIALSVODROUTE = '/specials/:id?';

const FREEROUTE = '/free_content';
const FREEVODROUTE = '/free_content/:id?';

const PARTNERSOFFEROUTE = '/partners_offer';

// const UNSUBSCRIBEROUTE = '/unsubscribe';
const MYACCOUNTCCROUTE = '/myaccount/creditcard';
// const MYACCOUNTCCROUTE = '/myaccount/:id?/creditcard';
const MYACCOUNTROUTE = '/myaccount/:id?/:subid?';
const INVOICEROUTE = '/invoice/:id?';

const WATCHLISTROUTE = '/mylist';

// const tvCountries = config.tvCountries.split(',');

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fadeEffect: '',
			displayCSS: 'block',
			outOfService: false,
			link: {
				contentType:false,
				contentId:false,
				checkContent:false,
				checkLogin:false
      },
      embedMode: false
		}

		this.props.initialize({
			languages: config.languages,
			options: {
				renderToStaticMarkup,
				renderInnerHtml: false,
				defaultLanguage: this.props.appLanguage ?
					this.props.appLanguage
					: config.languages[0].code
			},
      translation,
    });
	}

	async componentDidMount() {
		const { location, isAuthenticated, appHoldAccCheck, userProfile } = this.props;
		const str = navigator.platform + navigator.product + navigator.productSub + navigator.userAgent + navigator.vendor + navigator.vendorSub;
		let deviceID = (new Date()).getTime();
		if(localStorage.deviceID){
			deviceID = parseInt(localStorage.deviceID);
		}else{
			localStorage.setItem('deviceID', deviceID);
			if(localStorage.session_token){
				deviceID = 0;
			}
		}

		let hash = deviceID;
		let char = null;

    window.HBOGO_version = config.version;
    localStorage.removeItem('playing');
	this.props.userSetPreviousPage(null);
	this.props.userSetBeforePlay(null);
    this.props.userInitialBraze();
	this.props.sysInfoGetImpression();
	this.props.sysInfoGetRecommendation();
	this.props.sysInfoGetDeleteAcc();

    if (!document.referrer || !document.referrer.includes('/signin')) {
      localStorage.removeItem('before_sign_in');
    }

		for (var i = 0, iLen = str.length; i < iLen; i++) {
			char = str.charCodeAt(i);
			hash = ((hash << 5) - hash) + char;
			hash = hash & hash;
		}

		let isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) ||
			(navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
			!window.MSStream;
    let isAndroid = navigator.userAgent.indexOf('Android') > -1;
		let isAndroidTablet = isAndroid && (navigator.userAgent.indexOf('Mobile') < 0);

		let deviceType = "COMP";
		if(isIOS){
			deviceType = "TABL";
		}
		if(isAndroid || (/iPhone|iPod/.test(navigator.platform))){
			deviceType = "MOBI";
		}
		if (isTablet || isAndroidTablet){
			deviceType = "TABL";
		}

		const deviceInfo = {
			deviceName: navigator.platform,
			deviceType: deviceType,
			modelNo: navigator.productSub,
			serialNo: `${hash}`,
			appType: "Web"
		};



		let isLanding = (window.location.origin.indexOf(`${config.landing}`) > -1);

		const nonMobile = [
			'/resetparentalcontrolpin',
			'/resetpassword',
			'/updateparentalcontrols',
			'/privacy',
			'/activate',
			'/link',
			'/help/faq',
      '/loading',
      '/unsubscribe',
      '/terms_of_service',
      '/licenses',
      '/signup',
      '/signin',
      '/billing',
			'/parentalcontrol',
      '/myaccount',
      '/invoice',
      '/tvschedule',
		];

		if(document.referrer.indexOf(window.location.origin) > -1){
			// this.props.appSetLanding(false);
		}

		var parameterObject = appQuery();

		this.props.userSetDeviceInfo(deviceInfo);

		this.getCountry();

		if (localStorage.default_lang) {
			this.props.setActiveLanguage(localStorage.default_lang);
			this.props.appSetLanguage(localStorage.default_lang);
		}

		if (!localStorage.next_autoplay) {
			localStorage.setItem('next_autoplay', true);
		}

		if(isLanding){
			return;
		}

		let location_pathname = window.location.href.replace(window.location.origin, '');
		let location_hash = window.location.hash;
		let goLoading = true;

		// if # is one of the nonMobile, will replace # (not for case with parameters)
		if(nonMobile.indexOf(location_hash.replace('#', '/')) > -1){
			// window.location.replace(window.location.origin+location_hash.replace('#', '/'));
			this.props.appSetLanding(false);
			goLoading = false;
			this.props.history.replace(location_hash.replace('#', '/'));
		}

		if(location_hash.indexOf('#privacy') > -1){
			this.props.appSetLanding(false);
			goLoading = false;
			this.props.history.replace('/privacy');
			// window.location.replace(window.location.origin+'/privacy');
    }

		if(location_hash.indexOf('#terms_of_service') > -1 ){
			// window.location.replace(window.location.origin+'/terms_of_service');
			this.props.appSetLanding(false);
			goLoading = false;
			this.props.history.replace('/terms_of_service');
		}

		if(location_hash.indexOf('help/faq') > -1 ){
			// window.location.replace(window.location.origin+'/help/faq');
			this.props.appSetLanding(false);
			goLoading = false;
			this.props.history.replace('/help/faq');
		}

    if(location_hash.indexOf('help/contact_us') > -1 ){
			// window.location.replace(window.location.origin+'/help/faq');
			this.props.appSetLanding(false);
			goLoading = false;
			this.props.history.replace('/help/contact_us');
		}

    if(location_hash.indexOf('tvschedule') > -1 ){
			this.props.appSetLanding(false);
			goLoading = false;
			this.props.history.replace('/tvschedule');
		}

// WEB SDK LINK

/*
		if(location_pathname.split('?')[0] === '/link'){
			setTimeout(() => {
				window.location.replace(window.location.origin+location_pathname.replace('/', '/#'));
			}, 1000);
		}
*/

/* ----- THOSE GO EV IFRAME FROM MAIL ---------- */

// RESET PASSWORD

		if(location_hash.indexOf('#resetpassword') > -1 ){
		// if(location_pathname.indexOf('activate') > -1 || location_pathname.split('#')[0] === '/activate'){
      let search = location_pathname.split('?').length > 1 ? location_pathname.split('?')[1].replace('#mobile', '') : '';
			window.location.replace(window.location.origin + '/resetpassword' + (search ? '?' + search : ''));
			this.props.appSetLanding(false);
			goLoading = false;
    }

// UPDATE PARENTAL CONTROLS

		if(location_hash.indexOf('#updateparentalcontrols') > -1 ){
		// if(location_pathname.indexOf('activate') > -1 || location_pathname.split('#')[0] === '/activate'){
      let search = location_pathname.split('?').length > 1 ? location_pathname.split('?')[1].replace('#mobile', '') : '';
			window.location.replace(window.location.origin + '/updateparentalcontrols' + (search ? '?' + search : ''));
			this.props.appSetLanding(false);
			goLoading = false;
    }

// RESET PARENTAL CONTROL PIN

		if(location_hash.indexOf('#resetparentalcontrolpin') > -1 ){
		// if(location_pathname.indexOf('activate') > -1 || location_pathname.split('#')[0] === '/activate'){
      let search = location_pathname.split('?').length > 1 ? location_pathname.split('?')[1].replace('#mobile', '') : '';
			window.location.replace(window.location.origin + '/resetparentalcontrolpin' + (search ? '?' + search : ''));
			this.props.appSetLanding(false);
			goLoading = false;
    }


// ACTIVATION CODE

		if(location_hash.indexOf('#activate') > -1 ){
		// if(location_pathname.indexOf('activate') > -1 || location_pathname.split('#')[0] === '/activate'){
      let search = location_pathname.split('?').length > 1 ? location_pathname.split('?')[1].replace('#mobile', '') : '';
			window.location.replace(window.location.origin + '/activate' + (search ? '?' + search : ''));
			this.props.appSetLanding(false);
			goLoading = false;
    }

// UNSUBSCRIBE PAGE (FROM MAIL)
		if(location_hash.indexOf('#unsubscribe') > -1 ){
    // if(location_pathname.indexOf('unsubscribe') > -1 || location_pathname.split('#')[0] === '/unsubscribe'){
      let search = location_pathname.split('?').length > 1 ? location_pathname.split('?')[1].replace('#mobile', '') : '';
			window.location.replace(window.location.origin + '/unsubscribe' + (search ? '?' + search : ''));
			this.props.appSetLanding(false);
			goLoading = false;
    }

// SIGN IN (LOGIN ERROR)

		if (location_hash.indexOf('login-error') > -1) {
			this.props.history.push('/signin?login-error&'+((location_hash.split('?').length > 0)?location_hash.split('?')[1]:''));
			this.props.appSetLanding(false);
			goLoading = false;
		}

// SIGN IN (STANDALONE ERROR)

		if (location_hash.indexOf('standalone-error') > -1) {
			this.props.history.push('/signin?standalone-error&'+((location_hash.split('?').length > 0)?location_hash.split('?')[1]:''));
			this.props.appSetLanding(false);
			goLoading = false;
		}

// BILLING (BINDING ERROR)

		if (location_hash.indexOf('binding-error') > -1) {
			this.props.history.push('/billing?binding-error&'+((location_hash.split('?').length > 0)?location_hash.split('?')[1]:''));
			this.props.appSetLanding(false);
			goLoading = false;
		}

/*
		if ((isMobile || isIOS || isAndroid) && !nonMobile.some(v => location.pathname.includes(v)) && goLoading) {
			this.props.history.push('/mobile');
		} else if (location.pathname.indexOf('mobile') > -1) {
			this.props.history.push('/');
		}
*/

		if (isAuthenticated || (localStorage.session_token && localStorage.channel_partner_id) ||
			(window.location.origin.indexOf('vip') > -1 && !isMobile) ||
			location.pathname === '/login-error' ||
			location.pathname === '/resetparentalcontrolpin' ||
			location.pathname === '/resetpassword' ||
			location.pathname === '/updateparentalcontrols' ||
      location.pathname === '/activate' ||
      location.pathname === '/unsubscribe' ||
			location.pathname.indexOf('/help/') > -1 ||
			location.pathname.indexOf('/privacy') > -1 ||
			location.pathname.indexOf('/terms_of_service') > -1 ||
			location.pathname.indexOf('/licenses') > -1 ||
			location.pathname.indexOf('/series/') > -1 ||
			location.pathname.indexOf('/movies/') > -1 ||
			location.pathname.indexOf('/asian/') > -1 ||
			location.pathname.indexOf('/documentaries/') > -1 ||
			location.pathname.indexOf('/kids_family/') > -1 ||
			location.pathname.indexOf('/specials/') > -1 ||
			location.pathname.indexOf('/collections/') > -1 ||
			location.pathname.indexOf('/free_content/') > -1 ||
			location.pathname.indexOf('/extras/') > -1 ||
			location.pathname.indexOf('/livetv') > -1 ||
      location.pathname.indexOf('/profile') > -1 ||
      location.pathname.indexOf('/myaccount') > -1 ||
      location.pathname.indexOf('/invoice') > -1 ||
      location.pathname.indexOf('/tvschedule') > -1 ||
			(location.pathname === '/signin' && (location.search.indexOf('sessionToken') !== -1 && location.search.indexOf('channelPartnerID') !== -1))) {
				this.props.appSetLanding(false);
			if (isAuthenticated) {
        this.props.userGetProfile(localStorage.session_token, localStorage.channel_partner_id)
        .then(() => {
          if (appHoldAccCheck && location.pathname === '/' && location.hash === '') {
            if (userProfile && userProfile.accountData.accountStatus === 'Hold') {
							this.props.appSetPrompt({
								showPrompt: false,
								promptMode: null,
								jwtInfo: null,
								showHoldAccPrompt: true,
							});
              this.props.appSetHoldAccCheck(false);
            } else {
              this.props.appSetHoldAccCheck(false);
            }
          }
        })
        .catch(
					(err) => {
						// this.props.userLogout();
					},
				);
			}
		}

		if (this.props.location.pathname === '/loading' && goLoading) {
				let inIframe = false;
				try {
					inIframe = (window.self !== window.top);
				} catch (e) {
					inIframe = true;
				}
				if(!inIframe){
					if (parameterObject.sessionToken && parameterObject.channelPartnerID) {
						this.props.userGetProfile(parameterObject.sessionToken, parameterObject.channelPartnerID).then(
							(res) => {
								localStorage.setItem('session_token', parameterObject.sessionToken);
								localStorage.setItem('channel_partner_id', parameterObject.channelPartnerID);
								this.props.userSetAuthentication(true);
								let abandoner = (res.accountData.subscriptionStatus === 'false' && res.accountData.subscriptionExpDateTime === '-1');
								if(!abandoner && res.forceParentalControl && res.contactMessage.parentalControl === "false"){
									setTimeout(() => {
										this.props.history.push('/parentalcontrol');
									}, 5000);
								}else{
									setTimeout(() => {
										this.props.history.push('/');
									}, 2000);
								}
							},
							(err) => {
								setTimeout(() => {
									this.props.history.push('/');
								}, 2000);
							},
						);
					} else {
						setTimeout(() => {
							this.props.history.push('/');
						}, 3000);
					}
				}
		}

		if (window.location.pathname === '/socialshare') {
			if (parameterObject.token && parameterObject.channelPartnerID) {
				this.props.userGetProfile(parameterObject.token, parameterObject.channelPartnerID).then(
					(res) => {
						localStorage.setItem('session_token', parameterObject.token);
						localStorage.setItem('channel_partner_id', parameterObject.channelPartnerID);
						this.props.userSetAuthentication(true);
						// this.props.history.push(`/${parameterObject.contentType}/${parameterObject.contentId}`);
						if(parameterObject.embed && window.self !== window.top){
							this.setState({embedMode: true});
              this.props.appSetEmbedMode(true);
							if(parameterObject.startTime){
								this.props.appSetEmbedResume(parseInt(parameterObject.startTime));
								// this.props.history.push(`/${parameterObject.contentType}/${parameterObject.contentId}?embed&startTime=`+parameterObject.startTime);
								window.location.href = `${window.location.origin}/${parameterObject.contentType}/${parameterObject.contentId}?embed&startTime=`+parameterObject.startTime;
							}else{
								// this.props.history.push(`/${parameterObject.contentType}/${parameterObject.contentId}?embed`);
								window.location.href = `${window.location.origin}/${parameterObject.contentType}/${parameterObject.contentId}?embed`;
							}
						}else{
							// this.props.history.push(`/${parameterObject.contentType}/${parameterObject.contentId}`);
							window.location.href = `${window.location.origin}/${parameterObject.contentType}/${parameterObject.contentId}`;
						}
					},
					(err) => {
						// this.props.history.push(`/${parameterObject.contentType}/${parameterObject.contentId}`);
						window.location.href = `${window.location.origin}/${parameterObject.contentType}/${parameterObject.contentId}`;
					},
				);
			} else {
				// this.props.history.push(`/${parameterObject.contentType}/${parameterObject.contentId}`);
				if(parameterObject.embed && window.self !== window.top){
		      this.setState({embedMode: true});
          this.props.appSetEmbedMode(true);
					if(parameterObject.startTime){
						this.props.appSetEmbedResume(parseInt(parameterObject.startTime));
						// this.props.history.push(`/${parameterObject.contentType}/${parameterObject.contentId}?embed&startTime=`+parameterObject.startTime);
						window.location.href = `${window.location.origin}/${parameterObject.contentType}/${parameterObject.contentId}?embed&startTime=`+parameterObject.startTime;
					}else{
						// this.props.history.push(`/${parameterObject.contentType}/${parameterObject.contentId}?embed`);
						window.location.href = `${window.location.origin}/${parameterObject.contentType}/${parameterObject.contentId}?embed`;
					}
				}else{
					// this.props.history.push(`/${parameterObject.contentType}/${parameterObject.contentId}`);
					window.location.href = `${window.location.origin}/${parameterObject.contentType}/${parameterObject.contentId}`;
				}
			}
			// Might need to send to GA for deep link
    }


// EMDED PLAYER
    if (parameterObject.embed) {
      this.setState({embedMode: true});
      this.props.appSetEmbedMode(true);
			this.props.appSetEmbedResume(parseInt(parameterObject.startTime));
    }

		appEslint();

	}

	componentDidUpdate(prevProps, prevState) {
		const { appLoaded, isAuthenticated, appFirstLogin, location, userCountry, tvCountry, appLanguage } = this.props;
    const { displayCSS } = this.state;

    if (prevProps.isAuthenticated !== isAuthenticated && !isAuthenticated) { // log out
      this.props.promotionCancelPromo();
      this.props.userSetPreviousURL(null);
    }

		if (appLoaded !== prevProps.appLoaded && appLoaded) {
			setTimeout(() => {
				this.setState({ fadeEffect: 'animated fadeOut' });
        if (isAuthenticated && displayCSS === 'block') {
          var loadingTime = Math.round(performance.now());

          gaModule.timing({
            category: 'App Start Time',
            variable: 'load',
            value: loadingTime,
          });

          var event_category = 'HBOGO-Core';
          var event_action = 'App Loading Time';
          var event_label = {
            label: `label=App Loading Time`,
            loading_time: loadingTime.toString(),
          };
          gtmModule(event_category, event_action, event_label);
        }
				setTimeout(() => {
					this.setState({ displayCSS: 'none' });
				}, 1000);
			}, 1000);
		}

		if (userCountry && userCountry !== prevProps.userCountry) {
			this.props.translationGetList(userCountry);
		}

		if(!localStorage.default_lang && userCountry && userCountry !== prevProps.userCountry){
			var lang = (((navigator.userLanguage || navigator.language).replace('-', '_')).toLowerCase()).split('_')[0];
			if(userCountry){
				if(userCountry === 'TWN'){
					this.props.appSetLanguage('zh-Hant');
				}else{
					if(userCountry === 'HKG' && lang === 'zh'){
							this.props.appSetLanguage('zh-Hant');
					}else{
						this.props.appSetLanguage('en');
					}
				}
			}
		}

    if (appLanguage !== prevProps.appLanguage) {
		if(userCountry){
		this.props.overlayGetContent(userCountry);
		}
    }

		if (location.pathname === '/activate' && tvCountry !== prevProps.tvCountry) {
			if(!tvCountry && window.location.origin.indexOf('vip') < 0){
				this.props.history.push('/');
			}
		}

		if (!isAuthenticated && location.pathname !== '/link') {
			localStorage.removeItem('u');
			localStorage.removeItem('watchlist');
			localStorage.removeItem('parentalPINValid');
		}

		if (appFirstLogin && location.pathname !== '/signin') {
			this.props.appSetFirstLogin(false);
		}

    if (window.location.pathname === '/' && window.location.hash === '#home' && !isMobile) {
      this.props.appSetLanding(false);
      this.props.history.replace('/');
    }

    if (prevProps.location.pathname !== location.pathname) {
	  setTimeout(() => {
		let grecaptcha = document.getElementsByClassName('grecaptcha-badge')[0];
		if (grecaptcha) {
		  if (location.pathname === '/signin' || location.pathname === '/signup' || location.pathname === '/billing') {
			grecaptcha.style.removeProperty('visibility');
		  } else {
			grecaptcha.style.visibility = "hidden";
		  }
		}
	  }, 1000)
    }

  }

  handleDeeplinkLogin = (parameterObject) => {
    const { isUnifyCountry, userCountry } = this.props;
    const { link } = this.state;
    this.props.appSetHoldAccCheck(false);
    if(isUnifyCountry){
      if(parameterObject.token){
        let thisLink = link;
        thisLink.checkContent = true;
        this.setState({link:thisLink});
        this.getLinkLogin(parameterObject);
      }else if(parameterObject.contentId){
        this.getLinkContent(parameterObject, userCountry);
        let thisLink = link;
        thisLink.checkLogin = true;
        this.setState({link:thisLink});
		this.props.appSetPrompt({
			showPrompt: false,
		});
      }else{
        let thisLink = link;
        thisLink.checkLogin = true;
        thisLink.checkContent = true;
        this.setState({link:thisLink});
		this.props.appSetPrompt({
			showPrompt: false,
		});
      }
    }else{
      if(parameterObject.contentId){
        this.getLinkContent(parameterObject, userCountry);
		this.props.appSetPrompt({
			showPrompt: false,
		});
      }else{
        let thisLink = link;
        thisLink.checkContent = true;
        this.setState({link:thisLink});
		this.props.appSetPrompt({
			showPrompt: false,
		});
      }
      if (parameterObject.token && parameterObject.operatorId) {
        this.getLinkLogin(parameterObject);
      }else{
        let thisLink = link;
        thisLink.checkLogin = true;
        this.setState({link:thisLink});
		this.props.appSetPrompt({
			showPrompt: false,
		});
      }
    }
  }

	async getCountry() {
		const { location } = this.props;
		let country = '';
		await this.props.userGetCountry().then(
			(res) => {
				const { isVIP } = this.props;
				if ((
					(res !== 'MYS' && res !== 'SGP' && res !== 'IDN' && res !== 'PHL' && res !== 'HKG' && res !== 'TWN' && res !== 'THA')
				|| ((window.location.origin.indexOf('.sg') > -1 || window.location.origin.indexOf('sgweb') > -1) && res !== 'SGP')
				|| ((window.location.origin.indexOf('.my') > -1 || window.location.origin.indexOf('myweb') > -1) && res !== 'MYS')
				|| ((window.location.origin.indexOf('.id') > -1 || window.location.origin.indexOf('idweb') > -1) && res !== 'IDN')
				|| ((window.location.origin.indexOf('.ph') > -1 || window.location.origin.indexOf('phweb') > -1) && res !== 'PHL')
				|| ((window.location.origin.indexOf('.hk') > -1 || window.location.origin.indexOf('hkweb') > -1) && res !== 'HKG')
				|| ((window.location.origin.indexOf('.tw') > -1 || window.location.origin.indexOf('twweb') > -1) && res !== 'TWN')
				|| ((window.location.origin.indexOf('.th') > -1 || window.location.origin.indexOf('thweb') > -1) && res !== 'THA')
				)
				&& (location.pathname !== CONTACTROUTE && location.pathname !== FAQROUTE && location.pathname !== PRIVACYROUTE && location.pathname !== TERMSROUTE && location.pathname !== LEGALROUTE && location.pathname !== TVSCHEDULEROUTE) && !isVIP) {
					this.props.history.push('/outofservice');
					this.setState({ outOfService: true });
				} else if (location.pathname === '/outofservice') {
					this.props.history.push('/');
				} else {
					this.switchHostname(res);
				}
				country = res;

        // get overlay
        this.props.overlayGetContent(country);

        this.props.landingGetContent(country).then(res => {if(res) window.pageId = res.pageId});
			},
		);
		return country;
	}

	switchHostname(res){
		let subdomainList = ['.sg', 'sgweb', '.my', 'myweb', '.id', 'idweb', '.ph', 'phweb', '.hk', 'hkweb', '.tw', 'twweb', '.th', 'thweb'];
		let subdomains = subdomainList.filter(e=>{return (window.location.origin.indexOf(e) > -1)});
		if(subdomains.length){
			let subdomain = subdomains[0];
			if(res === 'SGP' && subdomain.indexOf('sgweb') < 0 && subdomain.indexOf('.sg') < 0){
				if(subdomain.indexOf('web') > -1){
					window.location.href = window.location.href.replace(window.location.origin, window.location.origin.replace(subdomain,'sgweb'));
				}else{
					window.location.href = window.location.href.replace(window.location.origin, window.location.origin.replace(subdomain,'.sg'));
				}
			}else if(res === 'MYS' && subdomain.indexOf('myweb') < 0 && subdomain.indexOf('.my') < 0){
				if(subdomain.indexOf('web') > -1){
					window.location.href = window.location.href.replace(window.location.origin, window.location.origin.replace(subdomain,'myweb'));
				}else{
					window.location.href = window.location.href.replace(window.location.origin, window.location.origin.replace(subdomain,'.my'));
				}
			}else if(res === 'IDN' && subdomain.indexOf('idweb') < 0 && subdomain.indexOf('.id') < 0){
				if(subdomain.indexOf('web') > -1){
					window.location.href = window.location.href.replace(window.location.origin, window.location.origin.replace(subdomain,'idweb'));
				}else{
					window.location.href = window.location.href.replace(window.location.origin, window.location.origin.replace(subdomain,'.id'));
				}
			}else if(res === 'PHL' && subdomain.indexOf('phweb') < 0 && subdomain.indexOf('.ph') < 0){
				if(subdomain.indexOf('web') > -1){
					window.location.href = window.location.href.replace(window.location.origin, window.location.origin.replace(subdomain,'phweb'));
				}else{
					window.location.href = window.location.href.replace(window.location.origin, window.location.origin.replace(subdomain,'.ph'));
				}
			}else if(res === 'HKG' && subdomain.indexOf('hkweb') < 0 && subdomain.indexOf('.hk') < 0){
				if(subdomain.indexOf('web') > -1){
					window.location.href = window.location.href.replace(window.location.origin, window.location.origin.replace(subdomain,'hkweb'));
				}else{
					window.location.href = window.location.href.replace(window.location.origin, window.location.origin.replace(subdomain,'.hk'));
				}
			}else if(res === 'TWN' && subdomain.indexOf('twweb') < 0 && subdomain.indexOf('.tw') < 0){
				if(subdomain.indexOf('web') > -1){
					window.location.href = window.location.href.replace(window.location.origin, window.location.origin.replace(subdomain,'twweb'));
				}else{
					window.location.href = window.location.href.replace(window.location.origin, window.location.origin.replace(subdomain,'.tw'));
				}
			}else if(res === 'THA' && subdomain.indexOf('thweb') < 0 && subdomain.indexOf('.th') < 0){
				if(subdomain.indexOf('web') > -1){
					window.location.href = window.location.href.replace(window.location.origin, window.location.origin.replace(subdomain,'thweb'));
				}else{
					window.location.href = window.location.href.replace(window.location.origin, 'www.hbogo.co.th');
				}
			}
		}
	}

	getLinkContent(parameterObject, userCountry){
		const { link } = this.state;
		const { appSupportLanguages } = this.props;

		let contentId = parameterObject.contentId;
		let lang = parameterObject.lang;
		if(lang){
			lang = lang.toLowerCase();
			if (appSupportLanguages.indexOf(lang.toLowerCase()) > -1){
				this.props.appSetLanguage(lang);
			}
		}
		let numstr = contentId.replace(/[^\d]/g, '');
		if(!contentId.includes("sr") && (numstr === null || (numstr !== contentId))){
			let liveContentId = contentId;
			if(contentId.includes("ch-")){
				liveContentId = contentId.split("-")[1];
			}
			this.props.liveGetChannel(userCountry, liveContentId).then(
				(res) => {
					let thisLink = link;
					thisLink.contentType = 'livetv';
					thisLink.contentId = liveContentId;
					thisLink.checkContent = true;
					this.setState({link:thisLink});
				},
				(err) => {
					let thisLink = link;
					thisLink.checkContent = true;
					this.setState({link:thisLink});
				}
			);
		}else if(contentId.includes("sr") || (numstr === contentId)){
			this.props.seriesGetSpecific(userCountry, contentId.replace('sr','')).then(
				(res) => {
					let thisLink = link;
					thisLink.contentId = 'sr'+contentId.replace('sr','');
					thisLink.contentType = "series";
					thisLink.checkContent = true;
					this.setState({link:thisLink});
				},
				(err) => {
					let thisLink = link;
					thisLink.checkContent = true;
					this.setState({link:thisLink});
				}
			);
		}else{
			this.props.searchGetContent(userCountry, contentId).then(
				(res) => {
					let thisLink = link;
					if(res.results[0]){
						if (res.results[0].contentType === "production") {
							thisLink.contentType = "movies";
						} else if (res.results[0].contentType === "episode") {
							thisLink.contentType = "series";
						} else if (res.results[0].contentType === "promo") {
							thisLink.contentType = "free_content";
						}
						thisLink.contentId = contentId;
						thisLink.checkContent = true;
						this.setState({link:thisLink});
					}else{
						thisLink.checkContent = true;
						this.setState({link:thisLink});
					}
				},
				(err) => {
					let thisLink = link;
					thisLink.checkContent = true;
					this.setState({link:thisLink});
				}
			);
		}
	}

	getLinkLogin(parameterObject){
		const { link } = this.state;
		this.props.userAuthenticateCustomerForOperator(parameterObject.token, parameterObject.operatorId).then(
				(result) => {
					let thisLink = link;
					thisLink.login = false;
					if(result.sessionToken && result.channelPartnerID){
						this.props.userGetProfile(result.sessionToken, result.channelPartnerID).then(
							(res) => {
								localStorage.setItem('session_token', result.sessionToken);
								localStorage.setItem('channel_partner_id', result.channelPartnerID);
								if(res.contactMessage.email){
									thisLink.profile = true;
								}else{
									thisLink.profile = false;
								}
								this.props.userSetAuthentication(true);
								thisLink.checkLogin = true;
								thisLink.login = true;
								this.setState({link:thisLink});
								this.props.appSetPrompt({
									showPrompt: false,
								});
							},
							(err) => {
								let thisLink = link;
								thisLink.checkLogin = true;
								this.setState({link:thisLink});
								this.props.appSetPrompt({
									showPrompt: false,
								});
							}
						);
					}
				},
				(err) => {
					let thisLink = link;
					thisLink.checkLogin = true;
					this.setState({link:thisLink});
					this.props.appSetPrompt({
						showPrompt: false,
					});
				}
			);
	}

	render() {
		const { fadeEffect, displayCSS, outOfService, embedMode} = this.state;
		const { searchOverlay, modalOverlay, settingOverlay, watchlistOverlay, userCountry, location, appLanding, isAuthenticated, history, outsideRegion, micrositeContent, partnerContent, appPrompt, appSupportLanguages, appLanguage, landingContent } = this.props;

    let hiddenClass = '';
		let customerPage = false;
		let helpPage = false;
		let mobileView = false;
		let activateView = false;
		let deeplinkView = false;
    let loadingView = false;
    let unsubscribeView = false;
    let invoiceView = false;
    let blankView = false;
    let hideTopPanel = false;
		let isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) ||
			(navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
			!window.MSStream;
    let isAndroid = navigator.userAgent.indexOf('Android') > -1;


		if ( searchOverlay || settingOverlay || watchlistOverlay || modalOverlay) {
			hiddenClass = 'no-scroll';
		}
		
		if (navigator.userAgent.toLowerCase().indexOf('googlebot') > -1) {
			mobileView = true;
		}

		if (location.pathname.toLowerCase().indexOf('signup') > -1) {
			customerPage = true;
			mobileView = true;
		}

		if (location.pathname.toLowerCase().indexOf('signin') > -1) {
			customerPage = true;
			mobileView = true;
		}

		if (location.pathname.toLowerCase().indexOf('parentalcontrol') > -1) {
			customerPage = true;
			mobileView = true;
		}

		if (location.pathname.toLowerCase().indexOf('billing') > -1) {
			customerPage = true;
			mobileView = true;
		}

		if (location.pathname.toLowerCase().indexOf('resetpassword') > -1) {
			customerPage = true;
			mobileView = true;
		}


		if (location.pathname.toLowerCase().indexOf('help') > -1) {
			helpPage = true;
			mobileView = true;
		}

		if ((location.pathname === '/resetparentalcontrolpin' || location.pathname === '/resetpassword' || location.pathname === '/updateparentalcontrols' || location.pathname === '/privacy' || location.pathname === '/terms_of_service' || location.pathname === '/licenses' || location.pathname === '/activate' || location.pathname === '/microsite' || location.pathname === '/unsubscribe' || location.pathname.includes('/myaccount') || location.pathname.includes('/invoice') || location.pathname === '/tvschedule') && isMobile) {
      if (location.pathname === '/microsite' && !config.micrositeFlag) return;
      mobileView = true;
		}

		if (location.pathname === '/activate') {
			activateView = true;
		}

		if (location.pathname === '/loading') {
			loadingView = true;
    }

    if (location.pathname === '/unsubscribe') {
      unsubscribeView = true;
    }

		if (window.self !== window.top && !embedMode){ // inside iframe shoould be loading view only.
			loadingView = true;
		}


    if (location.pathname.indexOf('myaccount') > -1) {
			customerPage = true;
      mobileView = true;
		}

    if (location.pathname.indexOf('/invoice') > -1) {
      invoiceView = true;
      mobileView = true;
    }


    if (location.pathname.indexOf('/link') > -1) {
			customerPage = true;
      mobileView = true;
    }

    if (location.pathname.indexOf('/partners_offer') > -1) {
      blankView = true;
      mobileView = true;
    }

    if (location.pathname === '/tvschedule') {
      mobileView = true;
      if (isMobile) {
        hideTopPanel = true;
      }
    }

		if (outsideRegion &&
			(location.pathname !== CONTACTROUTE
				&& location.pathname !== FAQROUTE
				&& location.pathname !== PRIVACYROUTE
				&& location.pathname !== TERMSROUTE
				&& location.pathname !== LEGALROUTE
        && location.pathname !== TVSCHEDULEROUTE)
			) {
			return (
        <Suspense fallback={<div style={{background:"#000"}}></div>}>
          <OutOfService />
        </Suspense>
      );
		}

		if (userCountry && (window.location.origin.indexOf(`${config.landing}`) > -1)) {
			return (
        <Suspense fallback={<div style={{background:"#000"}}></div>}>
          <Landing />
        </Suspense>
      );
		}

		const SignInComponent = (userCountry && userCountry === 'HKG')?HKSignIn:SignIn;
		const SignUpComponent = (userCountry && userCountry === 'HKG')?HKSignUp:SignUp;
		const ParentalControlComponent = (userCountry && userCountry === 'HKG')?HKParentalControl:ParentalControl;
		const BillingComponent = (userCountry && userCountry === 'HKG')?HKBilling:Billing;
		const ResetPasswordComponent = (userCountry && userCountry === 'HKG')?Iframe:ResetPassword;
		if(userCountry === 'HKG'){
			customerPage = false;
		}

		if(customerPage && !(window.self !== window.top)){ // customer page& not inside iframe
			$('body').css({'backgroundColor':'#f5f5f5'});
		}else{
			$('body').css({'backgroundColor':'#000'});
		}

		let siteDescription = '';
		let siteLanguage = appLanguage;
		let sitePath = location.pathname;
		let alternate = [];
		if (appSupportLanguages.indexOf(location.pathname.split('/')[1]) > -1){
			siteLanguage = location.pathname.split('/')[1];
			sitePath = location.pathname.substr(siteLanguage.length+1);
		}

		if(window.location.origin.indexOf('hk') > -1){
			if(translation_helmet_hk[sitePath] && translation_helmet_hk[sitePath][siteLanguage]){
				siteDescription = translation_helmet_hk[sitePath][siteLanguage];
			}else if(translation_helmet_hk[sitePath] && translation_helmet_hk[sitePath]['en']){
				siteDescription = translation_helmet_hk[sitePath]['en'];
			}
			if(translation_helmet_hk[sitePath]){
				alternate.push({lang:'en',link:window.location.origin+'/en'+sitePath});
				alternate.push({lang:'zh-Hant',link:window.location.origin+'/zh-Hant'+sitePath});
			}

		}else{
			if(translation_helmet[sitePath] && translation_helmet[sitePath][siteLanguage]){
				siteDescription = translation_helmet_hk[sitePath][siteLanguage];
			}else if(translation_helmet[sitePath] && translation_helmet[sitePath]['en']){
				siteDescription = translation_helmet[sitePath]['en'];
			}

			if(translation_helmet_hk[sitePath]){
				alternate.push({lang:'en',link:window.location.origin+'/en'+sitePath});
				if(window.location.origin.indexOf('hk') > -1 || window.location.origin.indexOf('tw') > -1 ){
					alternate.push({lang:'zh-Hant',link:window.location.origin+'/zh-Hant'+sitePath});
				}
				if(window.location.origin.indexOf('th') > -1){
					alternate.push({lang:'th',link:window.location.origin+'/tha'+sitePath});
				}
				if(window.location.origin.indexOf('id') > -1){
					alternate.push({lang:'id',link:window.location.origin+'/idn'+sitePath});
				}
			}
		}

		let facebookDomain = userCountry === 'TWN' ? 
			"40jcm569pam1jt15o0ks9o8nensh5v"
			: userCountry === 'MYS' ?
			"429hcojjdtfgp1i4auk76wnaj8gs9x"
			: userCountry === 'SGP' ?
			"k04d6bw26fcqo3x8zuthmqkf3oyj69"
			: userCountry === 'THA' ?
			"96j3kpdmunsiw47zcn5hfqvkz8n6iw"
			: userCountry === 'IDN' ?
			"i2ljpsfm4gnyetkgrawpolm333z3n5"
			: userCountry === 'PHL' ?
			"iao2apj7x8elbb5cil93e0n0fdqcc1"
			: "";

		return (
      <Suspense fallback={<div style={{background:"#000"}}></div>}>
			<div className={`App ${hiddenClass}`}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>HBO GO. It's HBO. Anywhere.</title>
				<meta name="description" content={siteDescription} />
				{alternate && alternate.map(e=><link rel="alternate" key={e.link} href={e.link} hreflang={e.lang}/>)}
				<meta name="facebook-domain-verification" content={facebookDomain} />
				{
					userCountry === 'PHL' &&
					<script async src={`https://www.googletagmanager.com/gtag/js?id=${config.googleTagManagerId}`}></script>
				}
				{
					userCountry === 'PHL' &&
					<script>{`
						window.dataLayer = window.dataLayer || [];
						function gtag(){dataLayer.push(arguments);}
						gtag('js', new Date());

						gtag('config', '${config.googleTagManagerId}');
					`}</script>
				}
				{
					userCountry === 'PHL' &&
					<script src={`https://www.googleoptimize.com/optimize.js?id=${config.googleOptimizeId}`}></script>
				}
				{
					config.appsflyerKey &&
					<script>
					{`!function (t, e, n, s, a, c, i, o, p) {
						t.AppsFlyerSdkObject = a,
							t.AF = t.AF || function () {
								(t.AF.q = t.AF.q || []).push([Date.now()].concat(Array.prototype.slice.call(arguments)))
							},
							t.AF.id = t.AF.id || i,
							t.AF.plugins = {},
							o = e.createElement(n),
							p = e.getElementsByTagName(n)[0],
							o.async = 1,
							o.src = "https://websdk.appsflyer.com?" +
							(c.length > 0 ? "st=" + c.split(",").sort().join(",") + "&" : "") +
							(i.length > 0 ? "af_id=" + i : ""),
							p.parentNode.insertBefore(o, p)
					}(window, document, "script", 0, "AF", "banners", { banners: { key: "${config.appsflyerKey}" } });

					// Show the banner
					AF('banners', 'showBanner');`}
					</script>
				}
	      	</Helmet>
        {
          appPrompt.showPrompt &&
            <Prompt mode={appPrompt.promptMode} onSwitchAcc={this.handleDeeplinkLogin}/>
        }
				{
						<Message />
				}
				{
					(userCountry && !loadingView) ?
						outOfService ?
							<OutOfService />
						:
							((isMobile || isIOS || isAndroid) && !mobileView) ?
                (window.location.pathname === '/' || (userCountry === 'TWN' && window.location.pathname === '/promo'))?
                <div>
                  {
                    landingContent && window.location.pathname === '/' ?
                      <LandingVer2 />
                      :
                      <Device
                        landing={false}
                        history={history}
                        location={location}
                      />
                  }
                </div>
                :
                (config.micrositeFlag && micrositeContent && micrositeContent.results.length > 0)?
                <Microsite
                  micrositeContent={micrositeContent.results[0]}
                  history={history}
                />
                : partnerContent && partnerContent.results.length > 0 ?
                <Route component={Partner} />
                :
                <Route component={NotFound} />
							:
								<div style={location.pathname === '/' ? {position: "relative"} : {}}>
									{
										!mobileView ?
											<div className={`app-first-load ${fadeEffect}`} id="app-first-load" style={{ display: displayCSS }}>
												<div className="splash-img-container" style={{ marginTop: window.innerHeight / 2 - 150 }} />
											</div>
										: ""
									}

									{
										!customerPage && appLanding && !isAuthenticated && !helpPage && (window.location.pathname === '/' || (userCountry === 'TWN' && window.location.pathname === '/promo') || (micrositeContent && micrositeContent.results.length < -1))?
											<div>
                        {
                          landingContent && window.location.pathname === '/'?
                            <LandingVer2 />
                            :
                            <Device
                              appLanguage={appLanguage}
                              landing={true}
                              history={history}
                              location={location}
                            />
                        }
                      </div>
                    : config.micrositeFlag && micrositeContent && micrositeContent.results.length > 0 ?
                      <Microsite
                        micrositeContent={micrositeContent.results[0]}
                        history={history}
                      />
										: partnerContent && partnerContent.results.length > 0 ?
											<Route component={Partner} />
                    : unsubscribeView ?
                      <Unsubscribe/>
										:
											<div className={appPrompt.showHoldAccPrompt ? 'popup-shown' : ''}>
                        {
                          appPrompt.showHoldAccPrompt &&
                          <Prompt
                            mode="hold-acc"
                            onClose={() => {
                              this.props.appSetPrompt({
																showPrompt: false,
																promptMode: null,
																jwtInfo: null,
																showHoldAccPrompt: false,
															})
                              this.props.appSetHoldAccCheck(false);
                            }}/>
                        }

												{!embedMode && !customerPage && !invoiceView && !blankView && !hideTopPanel && <Toppanel />}

												<div style={{minHeight: '95vh'}}>
													<Switch>
														<Routing exact path={HOMEROUTE} component={Home} type="public" />
														<Routing exact path={SIGNINROUTE} component={SignInComponent} type="public" />
														<Routing exact path={DEVICEROUTE} component={SignInComponent} type="public" />
														<Routing exact path={SIGNUPROUTE} component={SignUpComponent} type="public" />
														<Routing exact path={PARENTALCONTROLROUTE} component={ParentalControlComponent} type="public" />
														<Routing exact path={BILLINGROUTE} component={BillingComponent} type="public" />
														<Routing exact path={BILLINGCCROUTE} component={CreditCard} type="public" />
                            							<Routing exact path={BILLINGPROMOROUTE} component={CreditCard} type="public" />
														<Routing exact path={BILLINGOPERATORROUTE} component={Operator} type="public" />
														<Routing exact path={RESETPASSWORDROUTE} component={ResetPasswordComponent} type="public" />
														<Routing exact path={PROFILEROUTE} component={Profile} type="public" />
														<Routing exact path={RESETPINROUTE} component={Iframe} type="public" />
														<Routing exact path={UPDATEPARENTALROUTE} component={Iframe} type="public" />
														<Routing exact path={ACTIVATEROUTE} component={Activate} type="public" />

														{/* <Routing exact path={ORDERROUTE} component={OrderHBOGO} type="public" /> */}
                            <Routing exact path={PARTNERSOFFEROUTE} component={Promotion} type="public" />

														<Routing exact path={LEGALROUTE} component={SystemPage} type="public" />
														<Routing exact path={TERMSROUTE} component={SystemPage} type="public" />
														<Routing exact path={PRIVACYROUTE} component={SystemPage} type="public" />
														<Routing exact path={CONTACTROUTE} component={SystemPage} type="public" />
														<Routing exact path={FAQROUTE} component={SystemPage} type="public" />
                            {/* <Routing exact path={TVSCHEDULEROUTE} component={TVSchedule} type="public" /> */}

														<Routing exact path={MYACCOUNTCCROUTE} component={CreditCard} type="public" />
														<Routing exact path={MYACCOUNTROUTE} component={MyAccount} type="public" />
														<Routing exact path={INVOICEROUTE} component={Invoice} type="public" />
														<Routing exact path={MOVIELISTROUTE} component={EnhancedCollection} type="public" />
														<Routing exact path={MOVIEVODROUTE} component={VOD} type="public"/>

														<Routing exact path={ASIANROUTE} component={EnhancedCollection} type="public" />
														<Routing exact path={ASIANSERIESROUTE} component={IndividualSeries} type="public" />
														<Routing exact path={ASIANVODROUTE} component={VOD} type="public" />

														<Routing exact path={LIVETVALLROUTE} component={LiveTV} type="public" />
														<Routing exact path={LIVECHANNEL} component={LiveChannel} type="public" />

														<Routing exact path={SERIESLISTROUTE} component={EnhancedCollection} type="public" />
														<Routing exact path={SERIESROUTE} component={IndividualSeries} type="public" />
														<Routing exact path={EPISODEVODROUTE} component={VOD} type="public" />

														<Routing exact path={PROMOROUTE} component={VOD} type="public" />

														<Routing exact path={COLLECTIONLISTROUTE} component={Collection} type="public" />
														{/* <Routing exact path={COLLECTIONROUTE} component={IndividualCollection} type="public" /> */}
														<Routing exact path={COLLECTIONROUTE} component={EnhancedCollection} type="public" />

														<Routing exact path={DOCUMENTARYROUTE} component={EnhancedCollection} type="public" />
														<Routing exact path={DOCUMENETARYSERIESROUTE} component={IndividualSeries} type="public" />
														<Routing exact path={DOCUMENETARYVODROUTE} component={VOD} type="public" />

														<Routing exact path={FAMILYROUTE} component={EnhancedCollection} type="public" />
														<Routing exact path={FAMILYSERIESROUTE} component={IndividualSeries} type="public" />
														<Routing exact path={FAMILYVODROUTE} component={VOD} type="public" />

														<Routing exact path={SPECIALSROUTE} component={EnhancedCollection} type="public" />
														<Routing exact path={SPECIALSSERIESROUTE} component={IndividualSeries} type="public" />
														<Routing exact path={SPECIALSVODROUTE} component={VOD} type="public" />

														<Routing exact path={FREEROUTE} component={FreeContent} type="public" />
														<Routing exact path={FREEVODROUTE} component={VOD} type="public" />

                            <Routing exact path={WATCHLISTROUTE} component={Watchlist} type="public" />

														<Route component={NotFound} />
													</Switch>
												</div>
												{
													searchOverlay ?
														<Search
															history={this.props.history}
														/>
													: ""
												}

												{
													settingOverlay ?
														<Setting
															history={this.props.history}
														/>
													: ""
												}

												{
													watchlistOverlay ?
														<Watchlist
															history={this.props.history}
														/>
													: ""
												}

												{
													!helpPage && !customerPage && !mobileView && !activateView && !deeplinkView && !embedMode && !invoiceView && !blankView ?
														<Footer />
													: ""
												}
											</div>
									}
								</div>
					:
					(loadingView) ?
					<div className="loading-view">
					{ (!embedMode) &&
						<Translate id="app.loading" />
					}
					</div>
					:
					""
				}
			</div>
      </Suspense>
		);
	}
}

const mapStateToProps = state => ({
	toggledLeftPanel: state.app.toggledLeftPanel,
	searchOverlay: state.app.searchOverlay,
	modalOverlay: state.app.modalOverlay,
	settingOverlay: state.app.settingOverlay,
	watchlistOverlay: state.app.watchlistOverlay,
	appLoaded: state.app.appLoaded,
	isAuthenticated: state.user.isAuthenticated,
	userCountry: state.user.userCountry,
	tvCountry: state.user.tvCountry,
	isUnifyCountry: state.user.isUnifyCountry,
	outsideRegion:state.user.outsideRegion,
	isVIP: state.user.isVIP,
	appLanguage: state.app.appLanguage,
	appLanding: state.app.appLanding,
	appFirstLogin: state.app.appFirstLogin,
  translationList: state.translation.translationList,
  micrositeContent: state.microsite.micrositeSpecific,
  partnerContent: state.partner.partnerSpecific,
  appHoldAccCheck: state.app.appHoldAccCheck,
	appPrompt: state.app.appPrompt,
  userProfile: state.user.userProfile,
	appSupportLanguages: state.app.appSupportLanguages,
  landingContent: state.landing.landingContent,
});

const mapDispatchToProps = dispatch =>
bindActionCreators(
	{
		userSetDeviceInfo,
		userGetCountry,
		appSetLanding,
		appSetFirstLogin,
		appSetLanguage,
		userSetAuthentication,
		userGetProfile,
		userAuthenticateCustomerForOperator,
		userLogout,
		liveGetChannel,
		seriesGetSpecific,
		translationGetList,
		searchGetContent,
		micrositeGetSpecific,
		userValidateOperatorJWT,
		appSetHoldAccCheck,
		appSetEmbedResume,
		appSetEmbedMode,
		appSetPrompt,
		promotionCancelPromo,
		userSetPreviousURL,
		userInitialBraze,
		overlayGetContent,
		landingGetContent,
		userSetPreviousPage,
		sysInfoGetImpression,
		sysInfoGetRecommendation,
		sysInfoGetDeleteAcc,
		userSetBeforePlay,
	},
	dispatch
);

export default withRouter(withLocalize(connect(mapStateToProps, mapDispatchToProps)(App)));
