import axios from 'axios';
import config from '../../config';
import { getQuery } from '../../components/utils/common';
import apiInstance from './index';

const userInstance = apiInstance;

class UserApi {

	static UserLogin(payload) {
		return userInstance.post(`/hbouser/login?lang=${localStorage.default_lang}`, payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}


	static UserLogout(payload) {

		localStorage.removeItem('session_token');
		localStorage.removeItem('channel_partner_id');
		localStorage.removeItem('u');
		localStorage.removeItem('watchlist');
		localStorage.removeItem('parentalPINValid');

		return userInstance.post(`/hbouser/logout?lang=${localStorage.default_lang}`, payload)
		.then(function (response) {
			// localStorage.clear();
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
  }


	static UserForgotPassword(payload) {
		return userInstance.post(`/hbouser/forgotpassword?lang=${localStorage.default_lang}`, payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}



	static UserResetPassword(payload) {
		return userInstance.post(`/hbouser/resetpassword?lang=${localStorage.default_lang}`, payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static UserGetCountry() {
		let bundleId =  window.location.hostname;
		if(window.location.origin.indexOf('localhost') > -1 || window.location.origin.indexOf('192.168') > -1 || window.location.origin.indexOf('10.0.0') > -1){
			bundleId = 'sgweb-hbostag2.onwardsmg.net';
		}
		if (navigator.userAgent.toLowerCase().indexOf('googlebot') > -1) {
			return new Promise((resolve, reject) => {
				if(window.location.origin.indexOf('.sg') > -1){
					localStorage.setItem('real_territory', "SGP");
					resolve({"territory":"SGP","liveChannels":true});
				}else if(window.location.origin.indexOf('.my') > -1){
					localStorage.setItem('real_territory', "MYS");
					resolve({"territory":"MYS","liveChannels":true});
				}else if(window.location.origin.indexOf('id') > -1){
					localStorage.setItem('real_territory', "IDN");
					resolve({"territory":"IDN","liveChannels":true});
				}else if(window.location.origin.indexOf('.ph') > -1){
					localStorage.setItem('real_territory', "PHL");
					resolve({"territory":"PHL","liveChannels":true});
				}else if(window.location.origin.indexOf('.hk') > -1){
					localStorage.setItem('real_territory', "HKG");
					resolve({"territory":"HKG","liveChannels":true});
				}else if(window.location.origin.indexOf('.tw') > -1){
					localStorage.setItem('real_territory', "TWN");
					resolve({"territory":"TWN","liveChannels":true});
				}else if(window.location.origin.indexOf('.th') > -1){
					localStorage.setItem('real_territory', "THA");
					resolve({"territory":"THA","liveChannels":true});
				}else{
					localStorage.setItem('real_territory', "SGP");
					resolve({"territory":"SGP","liveChannels":true});
				}
			});
		}
		return userInstance.get(`/geog?lang=${localStorage.default_lang}&version=0&bundleId=${bundleId}`)
		.then(function (response) {
			if(window.location.origin.indexOf('localhost') > -1 || window.location.origin.indexOf('192.168') > -1){
				response.data.territory = 'SGP';
			}
      if (!response.data.territory && window.location.origin.indexOf('vip') > -1 && !localStorage.channel_partner_id) {
				response.data.territory = 'SGP';
			}
			localStorage.setItem('real_territory', response.data.territory);
			if(response.data.environment){
				if(response.data.environment !== localStorage.environment){
					localStorage.setItem('environment', response.data.environment);
					window.location.reload();
				}
			}else{
				if(localStorage.environment){
					localStorage.removeItem('environment');
					window.location.reload();
				}
			}


      if ("liveChannels" in response.data) {
        localStorage.setItem('liveChannels', response.data.liveChannels);
      } else {
        localStorage.setItem('liveChannels', true);
      }
      localStorage.setItem('ip', response.data.ip);
			return response.data;
		})
		.catch(function (error) {
			if (navigator.userAgent.toLowerCase().indexOf('googlebot') > -1) {
				if(window.location.origin.indexOf('.sg') > -1){
					localStorage.setItem('real_territory', "SGP");
					return {"territory":"SGP","liveChannels":true};
				}else if(window.location.origin.indexOf('.my') > -1){
					localStorage.setItem('real_territory', "MYS");
					return {"territory":"MYS","liveChannels":true};
				}else if(window.location.origin.indexOf('id') > -1){
					localStorage.setItem('real_territory', "IDN");
					return {"territory":"IDN","liveChannels":true};
				}else if(window.location.origin.indexOf('.ph') > -1){
					localStorage.setItem('real_territory', "PHL");
					return {"territory":"PHL","liveChannels":true};
				}else if(window.location.origin.indexOf('.hk') > -1){
					localStorage.setItem('real_territory', "HKG");
					return {"territory":"HKG","liveChannels":true};
				}else if(window.location.origin.indexOf('.tw') > -1){
					localStorage.setItem('real_territory', "TWN");
					return {"territory":"TWN","liveChannels":true};
				}else if(window.location.origin.indexOf('.th') > -1){
					localStorage.setItem('real_territory', "THA");
					return {"territory":"THA","liveChannels":true};
				}else{
					throw(error);
				}
			}else{
				throw(error);
			}
		});
	}

	static UserGetProfile(token, id, sess) {
		sess = (sess)?`&timer=${sess}`:'';
		return userInstance.get(`/hbouser/profile?lang=${localStorage.default_lang}&sessionToken=${token}&channelPartnerID=${id}&multiProfileId=0${sess}`)
		.then(function (response) {
			if('channelPartnerID' in response.data && response.data.channelPartnerID !== localStorage.getItem('channel_partner_id')){
				localStorage.setItem('channel_partner_id', response.data.channelPartnerID);
			}
			return response.data;
		})
		.catch(function (error) {
			if(error.response.status === 401){
				localStorage.removeItem('u');
				localStorage.removeItem('session_token');
				localStorage.removeItem('channel_partner_id');
				window.location.reload();
//				window.parent.location = `${window.parent.location.origin}/`;
			}
			throw(error);
		});
	}

	static UserAccountInfo(token, id) {
		return userInstance.get(`/hbouser/profile/accountInfo?lang=${localStorage.default_lang}&sessionToken=${token}&channelPartnerID=${id}&multiProfileId=0`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
  }

	static UserAuthenticateCustomerForOperator(token, id) {
		const payload = {
	    operatorJWT: token,
	    channelPartnerID: id
	  };
		return userInstance.post(`/hbouser/authenticateCustomerForOperator?lang=${localStorage.default_lang}`, payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
  }

  static UserCreateVoucherJWT(token, id) {
    const payload = {
      sessionToken: token,
      channelPartnerID: id
    };
    return userInstance.post(`/hbouser/createVoucherJWT?lang=${localStorage.default_lang}`, payload)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw(error);
    });
  }

  static UserSearchAccount(payload) {
    return userInstance.post(`/hbouser/searchAccount?lang=${localStorage.default_lang}`, payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
  }

  static UserUpdateProfile(payload) {
    return userInstance.post(`/hbouser/updateProfile?lang=${localStorage.default_lang}`, payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
  }

  static UserPatchProfile(payload) {
    return userInstance.patch(`/hbouser/profile?lang=${localStorage.default_lang}`, payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
  }

  static UserValidateOperatorJWT(payload) {
    return userInstance.post(`/hbouser/validateOperatorJWT?lang=${localStorage.default_lang}`, payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
  }

  static UserLinkOperatorAccount(payload) {
    return userInstance.post(`/hbouser/linkOperatorAccount?lang=${localStorage.default_lang}`, payload)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
			throw(error);
		});
  }

  static UserGetPaymentModes(payload, lang) {
    return userInstance.post(`/hbouser/subscription/getPaymentModes?lang=${localStorage.default_lang}`, payload)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
			throw(error);
		});
  }

  static UserGetProducts(token, id, storeType) {
	    return userInstance.get(`/hbouser/subscription/getProduct?lang=${localStorage.default_lang}&sessionToken=${token}&channelPartnerID=${id}&storeType=${storeType}&multiProfileId=0`)
	    .then(function (response) {
	      return response.data;
	    })
	    .catch(function (error) {
				throw(error);
			});
	  }

  static UserSignIn(payload, lang) {
    return userInstance.post(`/hbouser/login?lang=${localStorage.default_lang}`, payload)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
			throw(error);
		});
  }

  static UserSignUp(payload, lang) {
    return userInstance.post(`/hbouser/registerCustomer?lang=${localStorage.default_lang}`, payload)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
			throw(error);
		});
  }

  static UserAgeDeclaration(payload, lang) {
    return userInstance.post(`/hbouser/ageRestriction?lang=${localStorage.default_lang}`, payload)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
			throw(error);
		});
  }

	static UserCreateParentalControl(payload, lang) {
		return userInstance.post(`/hbouser/createParentalControl?lang=${localStorage.default_lang}`, payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static UserClassification(filter) {
    return userInstance.get(`/classification?lang=${localStorage.default_lang}&`+filter)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
			throw(error);
		});
  }

  static UserChangePassword(payload) {
    return userInstance.post(`/hbouser/changePassword?lang=${localStorage.default_lang}`, payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
  }

  static UserGetDevice(payload) {
    return userInstance.get(`/hbouser/device?lang=${localStorage.default_lang}&${getQuery(payload)}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
  }

  static UserUpdateDeviceDetail(payload) {
    return userInstance.post(`/hbouser/updateDeviceForAccount?lang=${localStorage.default_lang}`, payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
  }

  static UserDeleteDevice(payload) {
    return userInstance.delete(`/hbouser/device?lang=${localStorage.default_lang}`, {data: payload})
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
  }

  static UserGetSubscription(payload) {
    return userInstance.get(`/hbouser/subscription?${getQuery(payload)}&lang=${localStorage.default_lang}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
  }

	static AdyenPayments(payload) {
    return userInstance.post(`/hbouser/adyenPayments?lang=${localStorage.default_lang}`, payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
  }

	static UserGetPaymentMethods(payload) {
    return userInstance.post(`/hbouser/getPaymentMethods?lang=${localStorage.default_lang}`, payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
  }

	static UserAddSubscription(payload) {
    return userInstance.post(`/hbouser/subscription?lang=${localStorage.default_lang}`, payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
  }


	static UserUpdatePaymentInfo(payload) {
		return userInstance.post(`/hbouser/updatePaymentInfo?lang=${localStorage.default_lang}`, payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

  static UserGetSubscriptionHistory(payload) {
    return userInstance.post(`/hbouser/getAllPayments`, payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
  }

  static UserCheckCredentials(payload) {
    return userInstance.post(`/hbouser/checkCredentials`, payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
  }

  static UserChangeService(payload) {
    return userInstance.post(`/hbouser/changeService`, payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
  }

  static UserRemoveSubscription(payload) {
    return userInstance.post(`/hbouser/removeSubscription`, payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
  }

	static UserIsCustomerEligibleForFreeTrial(payload) {
    return userInstance.post(`/hbouser/isEligibleFreetrial?lang=${localStorage.default_lang}`, payload)
		.then(function (response) {
			return {freeTrialAvailable: true};
		})
		.catch(function (error) {
			return {freeTrialAvailable: false};
		});
  }

  static UserIsCustomerEligibleForPromotion(payload) {
    return userInstance.post(`/hbouser/isUserEligibleForPromotion?lang=${localStorage.default_lang}`, payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
  }

  static UserRemoveAllDevices(payload) {
    return userInstance.post(`/hbouser/removeAllDevices`, payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
  }


}

export default UserApi;
